import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import useAxiosInstance from "./utils/axiosIntance";

type PlayerMoveResponse = {
  isSuccess: boolean;
};
type PlayerMovePayload = {
  cardFace: string;
  cellId: string;
};
const usePlayerMove = () => {
  const axios = useAxiosInstance();
  const { mutate: playerMove } = useMutation<
    AxiosResponse<PlayerMoveResponse>,
    AxiosError,
    PlayerMovePayload
  >({
    mutationFn: (payload: PlayerMovePayload) => {
      return axios.patch(`/player/move`, payload, {
        withCredentials: true,
      });
    },
  });
  return playerMove;
};

export default usePlayerMove;
