import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import useAxiosInstance from "./utils/axiosIntance";

type AnnounceSequenceResponse = {
  isSuccess: boolean;
};
type AnnounceSequencePayload = {
  potentialSequence: string[];
};
const useAnnounceSequence = () => {
  const axios = useAxiosInstance();
  const { mutate: announceSequence } = useMutation<
    AxiosResponse<AnnounceSequenceResponse>,
    AxiosError,
    AnnounceSequencePayload
  >({
    mutationFn: (payload) => {
      return axios.patch(`/player/announceSequence`, payload, {
        withCredentials: true,
      });
    },
  });
  return announceSequence;
};

export default useAnnounceSequence;
