import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import useAxiosInstance from "./utils/axiosIntance";

type LockGameResponse = {
  isSuccess: boolean;
};
type LockGamePayload = {
  status: boolean;
};
const useLockGame = () => {
  const axios = useAxiosInstance();
  const { mutate: lockGame } = useMutation<
    AxiosResponse<LockGameResponse>,
    AxiosError,
    LockGamePayload
  >({
    mutationFn: (payload: LockGamePayload) => {
      return axios.patch(`/game/lock`, payload, {
        withCredentials: true,
      });
    },
  });
  return lockGame;
};

export default useLockGame;
