import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import useAxiosInstance from "./utils/axiosIntance";

type StartGamePayload = {};
type StartGameResponse = {
  isSuccess: boolean;
};
const useStartGame = () => {
  const axios = useAxiosInstance();
  const { mutate: startGame } = useMutation<
    AxiosResponse<StartGameResponse>,
    AxiosError,
    StartGamePayload
  >({
    mutationFn: () => {
      return axios.patch(
        `/game/start`,
        {},
        {
          withCredentials: true,
        }
      );
    },
  });
  return startGame;
};

export default useStartGame;
