import { useMutation } from "@tanstack/react-query";
import useAxiosInstance from "./utils/axiosIntance";

const useResetGame = () => {
  const axios = useAxiosInstance();
  const { mutate: resetGame } = useMutation({
    mutationFn: (payload: {}) => {
      return axios.post(`/game/resetGame`, {}, { withCredentials: true });
    },
  });
  return resetGame;
};

export default useResetGame;
