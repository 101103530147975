import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import useAxiosInstance from "./utils/axiosIntance";

type KickPlayerPayload = {
  playerId: string;
};
type KickPlayerResponse = {
  isSuccess: boolean;
};
const useKickPlayer = () => {
  const axios = useAxiosInstance();
  const { mutate: kickPlayer } = useMutation<
    AxiosResponse<KickPlayerResponse>,
    AxiosError,
    KickPlayerPayload
  >({
    mutationFn: (payload: KickPlayerPayload) => {
      return axios.patch(
        `/game/kick`,
        {
          playerId: payload?.playerId || "",
        },
        {
          withCredentials: true,
        }
      );
    },
  });
  return kickPlayer;
};

export default useKickPlayer;
