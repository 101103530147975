import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import useAxiosInstance from "./utils/axiosIntance";

type SetPlayerStatusPayload = {
  status: boolean;
};
type SetPlayerStatusResponse = {
  success: boolean;
};
const useSetPlayerStatus = () => {
  const axios = useAxiosInstance();
  const { mutate: setPlayerStatus } = useMutation<
    AxiosResponse<SetPlayerStatusResponse>,
    AxiosError,
    SetPlayerStatusPayload
  >({
    mutationFn: (payload: SetPlayerStatusPayload) => {
      return axios.patch(`/game/ready`, payload, {
        withCredentials: true,
      });
    },
  });
  return setPlayerStatus;
};

export default useSetPlayerStatus;
