import { useMutation } from "@tanstack/react-query";
import useAxiosInstance from "./utils/axiosIntance";

const useEndGame = () => {
  const axios = useAxiosInstance();
  const { mutate: endGame } = useMutation({
    mutationFn: (payload: {}) => {
      return axios.post(`/game/endGame`, payload, {
        withCredentials: true,
      });
    },
  });
  return endGame;
};

export default useEndGame;
