import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import useAxiosInstance from "./utils/axiosIntance";

type EndPlayerTurnPayload = {};
type EndPlayerTurnResponse = {
  isSuccess: boolean;
};
const useEndPlayerTurn = () => {
  const axios = useAxiosInstance();
  const { mutate: endPlayerTurn } = useMutation<
    AxiosResponse<EndPlayerTurnResponse>,
    AxiosError,
    EndPlayerTurnPayload
  >({
    mutationFn: () => {
      return axios.patch(`/player/endTurn`, {}, { withCredentials: true });
    },
  });
  return endPlayerTurn;
};

export default useEndPlayerTurn;
