import axios, { AxiosError } from "axios";
import { API_BASE_URL } from "../constants";
import { useToast } from "../../hooks/useToast";

const useAxiosInstance = () => {
  const toast = useToast();

  const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError<{ message: string }>) => {
      if (error.response) {
        const { message = "" } = error?.response?.data || {};
        toast?.error(message || "Something went wrong !");
      }
      return Promise.reject(error);
    }
  );
  return axiosInstance;
};

export default useAxiosInstance;
