import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import useAxiosInstance from "./utils/axiosIntance";

type GetNewCardPayload = {};
type GetNewCardResponse = {
  isSuccess: boolean;
};
const useGetNewCard = () => {
  const axios = useAxiosInstance();
  const { mutate: getNewCard } = useMutation<
    AxiosResponse<GetNewCardResponse>,
    AxiosError,
    GetNewCardPayload
  >({
    mutationFn: () => {
      return axios.patch(`/player/getCard`, {}, { withCredentials: true });
    },
  });
  return getNewCard;
};

export default useGetNewCard;
