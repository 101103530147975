import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import useAxiosInstance from "./utils/axiosIntance";

type DiscardCardResponse = {
  isSuccess: boolean;
  errorMessage?: string;
};
type DiscardCardPayload = {
  cardFace: string;
};
const useDiscardCard = () => {
  const axios = useAxiosInstance();
  const { mutate: discardCard } = useMutation<
    AxiosResponse<DiscardCardResponse>,
    AxiosError,
    DiscardCardPayload
  >({
    mutationFn: (payload) => {
      return axios.patch(`/player/discardCard`, payload, {
        withCredentials: true,
      });
    },
  });
  return discardCard;
};

export default useDiscardCard;
